$(() => {

  // Event listener for scope buttons
  $('.scope_button_dynamic').on('click', function(event) {
    event.preventDefault();

    const scopeType = $(this).data('scope-type');
    const scopeValue = $(this).data('scope-value');

    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set(`${scopeType}`, scopeValue);

    // Preserve all other query parameters and reload the page
    window.location.href = url.toString();
  });

  // Automatically submit the form when the bank account dropdown changes
  $('#money_movement_plaid_transactions_grid_bank_account_uuid').on('change', function(ev, picker) {
    $("#new_money_movement_plaid_transactions_grid_money_movement_plaid_transactions_grid").trigger( "submit" );
  });

  // Automatically submit the form when the posted only checkbox changes
  $('#money_movement_plaid_transactions_grid_show_pending').on('change', function(ev, picker) {
    $("#new_money_movement_plaid_transactions_grid_money_movement_plaid_transactions_grid").trigger( "submit" );
  });

})
