import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

function SearchableSelectInput({ questionName, question, value, onChange, onBlur }) {
  const formattedOptions = Object.entries(question.options).map(([value, label]) => ({ value, label }));
  const formattedValue = formattedOptions.find(option => option.value === value) || null;
  return (
    <Select
      className="question_block_select_input"
      id={questionName}
      name={questionName}
      closeMenuOnSelect
      defaultValue={formattedValue}
      isMulti={false}
      options={formattedOptions}
      onChange={onChange}
      onBlur={onBlur}
      required={!question.optional}
    />
  );
}

SearchableSelectInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  question: PropTypes.shape({
    locked: PropTypes.bool,
    options: PropTypes.object.isRequired,
    optional: PropTypes.bool,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

SearchableSelectInput.defaultProps = {
  value: "",
};

export default SearchableSelectInput;
